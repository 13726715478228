<template>
  <div>
    <el-page-header
      class="u-bottom-margin--2x"
      title="Back"
      :content="`Assgin Product to Provider ${productId}`"
      @back="goBack"
    >
    </el-page-header>
    <div>
      <div class="product-list-filter">
        <el-input
          class="u-left-margin--2x"
          :placeholder="$t('assginDistributerPageDistributerLabel')"
          v-model="filters.name"
          @change="filtersChanged('name', $event)"
        >
        </el-input>
      </div>

      <div class="u-display-flex u-justify-content--center u-bottom-margin--2x">
        <el-button type="primary" @click="saveProviders">
          Save & proceed
        </el-button>
      </div>

      <el-table
        class="is-infinite-load"
        :data="providersList"
        style="width: 100%"
        border
        :span-method="objectSpanMethod"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="image"
          :label="$t('assginDistributerPageDistributerLabel')"
        >
          <template slot-scope="scope">
            <div>
              <div>
                <div class="u-font--medium">
                  الاسم:
                  {{ scope.row.name }}
                </div>
              </div>

              <div class="u-font--xsmall">
                <strong>{{ scope.row.id }}</strong> :ID
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Unit Name" prop="unit.name"></el-table-column>
        <el-table-column :label="$t('assginDistributerPageCodeLabel')">
          <template slot-scope="scope">
            <el-input
              :placeholder="$t('assginDistributerPageCodeInputPlaceholder')"
              v-model="scope.row.code"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('assginDistributerPageFaturaWeightFactorLabel')"
        >
          <template slot-scope="scope">
            <el-input
              :placeholder="
                $t('assginDistributerPageFaturaWeightFactorInputPlaceholder')
              "
              v-model="scope.row.countFactor"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="Max Per Order">
          <template slot-scope="scope">
            <el-input
              placeholder="Max Per Order"
              v-model="scope.row.maxPerOrder"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="originalPrice"
          :label="$t('assginDistributerPageCashValueLabel')"
        >
          <template slot-scope="scope">
            <el-input
              :placeholder="$t('assginDistributerPageInputCashPlaceholder')"
              v-model="scope.row.price"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('assginDistributerPageProductAvailabilityLabel')"
          width="220"
        >
          <template slot-scope="scope">
            <div class="u-text--center">
              <el-switch
                v-model="scope.row.isInStock"
                :active-text="
                  $t('assginDistributerPageProductAvailabilitySwichActiveValue')
                "
                :inactive-text="
                  $t(
                    'assginDistributerPageProductAvailabilitySwichInActiveValue'
                  )
                "
                :active-value="true"
                :inactive-value="false"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('assginDistributerPageFaturaProductLabel')">
          <template slot-scope="scope">
            <div class="u-text--center">
              <el-checkbox
                v-model="scope.row.isFaturaProduct"
                :true-label="1"
                :false-label="0"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="u-display-flex u-justify-content--center u-top-padding--2x">
      <el-pagination
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :total="pagination.totalResultsCount"
        :current-page="paginationCurrentPage"
        :page-size="50"
        @current-change="handlePagination"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { providersService } from "../../services/providers.service";
import { mapGetters } from "vuex";
import { productsService } from "../../services";

export default {
  name: "AssignProductPage",
  data() {
    return {
      pagination: {},
      providersList: [],
      productUnits: [],
      filters: {
        // type: "distributer",
      },
    };
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    paginationCurrentPage() {
      return this.pagination.nextPage
        ? this.pagination.nextPage - 1
        : this.pagination.totalPages;
    },
    productId() {
      return this.$route.params.id;
    },
  },
  async mounted() {
    await this.getProductUnits();
    await this.getProviders();
    this.mapProvidersListWithUnits();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    objectSpanMethod({ row, columnIndex }) {
      if (columnIndex === 0) {
        const currentProvider = this.providersList.filter(
          (item) => item.providerId === row.providerId
        );
        if (currentProvider && currentProvider.length > 1) {
          const currentObjectIndex = currentProvider.findIndex(
            (item) =>
              item.providerId === row.providerId && item.unitId == row.unitId
          );
          if (currentObjectIndex === 0) {
            return {
              rowspan: currentProvider.length,
              colspan: 1,
            };
          } else {
            return {
              rowspan: 1,
              colspan: 0,
            };
          }
        }
      }
      return {
        rowspan: 1,
        colspan: 1,
      };
    },
    handlePagination(val) {
      const pageLoading = this.$loading();
      providersService
        .listAllProviders(val, this.filters)
        .then((response) => {
          this.providersList = response.providersList;
          this.pagination = response.pagination;

          this.$router.push({
            query: {
              page: response.pagination.nextPage
                ? response.pagination.nextPage - 1
                : response.pagination.totalPages,
            },
          });
        })
        .finally(() => {
          pageLoading.close();
        });
    },
    checkProviderData() {
      let isValid = true;
      for (let i = 0; i < this.providersList.length; i++) {
        const element = this.providersList[i];
        if (
          (element.code ||
            element.maxPerOrder ||
            element.countFactor ||
            element.isFaturaProduct ||
            element.isInStock) &&
          !element.price
        ) {
          this.$notify({
            type: "error",
            title: "Error",
            message: "Please, add the cash price",
          });
          isValid = false;
          break;
        }
      }
      return isValid;
    },
    async saveProviders() {
      const valid = this.checkProviderData();
      if (valid) {
        let filteredProviders = this.providersList.filter((item) => {
          return (
            (item.code ||
              item.maxPerOrder ||
              item.countFactor ||
              item.isFaturaProduct ||
              item.isInStock) &&
            item.price
          );
        });
        if (filteredProviders.length < 1) {
          this.$notify({
            type: "error",
            title: "Error",
            message: "Please, Assign at least one provider",
          });
          return;
        }
        let assigindProviders = filteredProviders.map((provider) => {
          return {
            distributerId: provider.providerId,
            unitId: provider.unitId,
            maxPerOrder: provider.maxPerOrder,
            code: provider.code,
            inStock: provider.isInStock ? provider.isInStock : false,
            price: provider.price,
            countFactor: provider.countFactor,
            isFaturaProduct: provider.isFaturaProduct,
          };
        });

        try {
          await providersService
            .assignProvidersToProduct(this.productId, assigindProviders)
            .then(() => {
              this.$notify({
                type: "success",
                title: "Success",
                message: "Product Assigned Successfully",
              });
              this.$router.push({
                name: "productsList",
              });
            });
        } catch (err) {
          this.$notify({
            type: "error",
            title: "Error",
            message: err.response.data.error,
          });
        }
      }
    },

    async getProviders(page = 1) {
      const filters = this.filters;
      filters.activity = ["provides"];
      await providersService.listAllProviders(page, filters).then((res) => {
        this.providersList = res.providersList;
        this.pagination = res.pagination;
      });
    },
    async getProductUnits() {
      try {
        const unitsRes = await productsService.getUnitsofCertainProduct(
          this.productId
        );
        this.unitDetails = unitsRes.data.data;
      } catch (err) {
        console.log(err);
      }
    },
    mapProvidersListWithUnits() {
      const newProvidersList = [];
      this.providersList.forEach((provider) => {
        this.unitDetails.forEach((unit) => {
          newProvidersList.push({
            name: provider.name,
            providerId: provider.id,
            ...unit,
          });
        });
      });
      this.providersList = newProvidersList;
    },
    filtersChanged(key, value) {
      if (_.isEmpty(value)) {
        this.filters[key] = undefined;
      }

      this.getProviders(1, { ...this.filters });

      this.$router.push({
        query: {
          page: 1,
        },
      });
    },
    tableRowClassName({ row }) {
      if (
        (row.code ||
          row.maxPerOrder ||
          row.countFactor ||
          row.isFaturaProduct ||
          row.isInStock) &&
        row.price
      ) {
        return "u-bg--green-lightest no-hover";
      } else if (
        (row.code ||
          row.maxPerOrder ||
          row.countFactor ||
          row.isFaturaProduct ||
          row.isInStock) &&
        !row.price
      ) {
        return "u-bg--red-lightest no-hover";
      }

      return "no-hover";
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/wrench-kit/settings/_settings.global.scss";

.c-distributers-header {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.c-distributers-header__title {
  margin-right: auto;
  font-size: 24px;
  font-weight: bold;
}
.infinite-list-wrapper {
  height: 90vh;
}

.el-table.is-infinite-load {
  position: inherit !important;
}
.el-table {
  * {
    word-break: break-word;
  }
}

.product-list-filter {
  display: flex;
  min-height: 40px;
  border: 1px solid $wrench-color-sky;
  margin-bottom: $wrench-spacing-2x;
  padding: 8px;
}
</style>
